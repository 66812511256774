<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" >新增</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题">
            </el-table-column>
            <el-table-column
                prop="type"
                label="类型"
                width="80">
            </el-table-column>
            <el-table-column
                prop="businessId"
                label="业务主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="140">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleView(scope.row)">详情</el-button>
                    <el-button type="text" @click="handleMarge(scope.row)">合成</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <el-dialog title="章节" :visible.sync='open' width='80%' :before-close='cancel'>
      <br id="section1">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ detail.title }}</span>
        </div>
        <div>
          <div v-for="(item,index) in detail.newContent" :key="index">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ '文章：' + item.sort }}</span>
                <div style="float: right;">
                  <el-button type="text" @click="handleAddGoods(index, -1)">在之前添加商品</el-button>
                  <el-button type="text" @click="handleAddGoods(index, 1)">在之后添加商品</el-button>
                </div>
              </div>
              <div>{{ item.content }}</div>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图片</span>
              </div>
              <img v-for="(url, inp) in item.url" :src="url" :key="inp" width="30%" alt="暂无图片" style="margin-left: 5px;">
            </el-card>
          </div>
        </div>
      </el-card>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitFormRight'>保 存</el-button>
        </div>
    </el-dialog>
    
    <el-dialog title="商品信息" :visible.sync='goodsOpen' width='80%' :before-close='cancelGoods'>
      <div>
        <el-select v-model="goodsInfo.parentId" placeholder="请选择分类" @change="handleChange">
          <el-option
            v-for="item in goodsLevelOneList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="goodsInfo.levelId" placeholder="请选择分类" @change="handleTwoChange">
          <el-option
            v-for="item in goodsLevelTwoList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <screen-table
          class="screen-conent"
          table-class="custom-table"
          header-row-class-name="custom-table-header"
          :data="goodsData.tableData">
              <el-table-column
                  fixed
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column
                  prop="id"
                  label="主键"
                  width="70">
              </el-table-column>
              <el-table-column
                  prop="levelId"
                  label="分类">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="名称">
              </el-table-column>
              <el-table-column
                  prop="headName"
                  label="负责人名称">
              </el-table-column>
              <el-table-column
                  prop="headPhone"
                  label="负责人电话">
              </el-table-column>
              <el-table-column
                  prop="status"
                  label="状态">
              </el-table-column>
              <el-table-column
                  prop="updateUserId"
                  label="修改人">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="创建时间">
                  <template slot-scope='scope'>
                      <span>{{ parseTime(scope.row.createTime) }}</span>
                  </template>
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  width="100">
                  <template slot-scope="scope">
                      <el-button type="text" @click="handleSelectGoods(scope.row)">选择</el-button>
                  </template>
              </el-table-column>
      </screen-table>

      <div class="screen-footer text-right">
          <el-pagination
              background
              layout="sizes, prev, pager, next, total"
              :page-sizes="[10, 20, 50, 100]"
              :total="goodsData.total"
              @current-change="handleGoodsPageNo"
              @size-change="handleGoodsPageSize"
          >
          </el-pagination>
      </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { contentAiPage, updateContentAi, margeVideoByContentId } from '@/service/headlines';
import { getGoodsPage, getGoodsLevelAll } from '@/service/goods';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 是否显示弹出层
      open: false,
      detail: {},
      goodsInfo: {},
      goodsOpen: false,
      goodsLevelOneList: [],
      goodsLevelTwoList: [],
      goodsData: {
        queryParams: {
          pageNo: 1,
          pageSize: 10,
          search: undefined
        },
        total: 0,
        tableData: []
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      contentAiPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    getGoodsLevel(id){
      getGoodsLevelAll({id}).then(response => {
        if(id){
          this.goodsLevelTwoList = response['data'];
        } else {
          this.goodsLevelOneList = response['data'];
        }
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    getGoodsList() {
      getGoodsPage(this.goodsData.queryParams).then(response => {
        this.goodsData.tableData = response['data']['records'];
        this.goodsData.total = response['data']['total'];
      });
    },
    handleGoodsPageNo(pageNo) {
        this.goodsData.queryParams = {
            ...this.goodsData.queryParams,
            pageNo
        };
        this.getGoodsList();
    },
    handleGoodsPageSize(pageSize) {
        this.goodsData.queryParams = {
            ...this.goodsData.queryParams,
            pageSize
        };
        this.getGoodsList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleView(row){
      let newContent = JSON.parse(row.content);
      this.detail = {
        ...row,
        newContent
      };
      this.getGoodsLevel();
      this.open = true;
    },
    cancel(){
      this.open = false;
      this.detail = {};
    },
    handleMarge(row){
      margeVideoByContentId({id:row.id}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    handleAddGoods(index, position){
      this.goodsInfo.index = index;
      this.goodsInfo.position = position;
      if(this.goodsLevelOneList && this.goodsLevelOneList.length > 0){
        let pId = this.goodsLevelOneList[0]['id'];
        this.goodsInfo.parentId = pId;
        this.getGoodsLevel(pId);
      }
      this.goodsOpen = true;
    },
    cancelGoods(){
      this.goodsOpen = false;
      this.goodsInfo = {};
      this.goodsData = {
        queryParams: {
          pageNo: 1,
          pageSize: 10,
          search: undefined
        },
        total: 0,
        tableData: []
      };
    },
    handleChange(id){
      this.getGoodsLevel(id);
    },
    handleTwoChange(){
      this.goodsData.queryParams = {
        ...this.goodsData.queryParams,
        levelId: this.goodsInfo.levelId
      };
      this.getGoodsList();
    },
    handleSelectGoods(row){
      let content = JSON.parse(row.content);
      let index = this.goodsInfo.index;
      let position = this.goodsInfo.position;
      let addIndex = index + position;
      if(addIndex < 0){
        addIndex = 0;
      }
      content = content.map((item, i) => {
        item.sort = i + addIndex;
        return item;
      });
      let newContent = this.detail.newContent;
      newContent.splice(addIndex, 0, ...content);
      newContent.map((item, index) => {
        item.sort = index + 1;
        return item;
      });
      this.detail = {
        ...this.detail,
        content: JSON.stringify(newContent),
        newContent
      };
      this.cancelGoods();
    },
    submitFormRight(){
      updateContentAi(this.detail).then(response => {
        if (response.code === 0) {
          this.cancel();
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    }
  }
};
</script>